import React from 'react';
import { Card, CardHeader, CardBody, Collapse, Row, Col } from 'reactstrap';

function LykeStudents() {
	const [collapses, setCollapses] = React.useState([0]);
	const changeCollapse = (collapse) => {
		if (collapses.includes(collapse)) {
			setCollapses(collapses.filter((prop) => prop !== collapse));
		} else {
			setCollapses([...collapses, collapse]);
		}
	};
	return (
		<>
			<div className='faqCollapse'>
				<div id='collapse' className='faqLyke'>
					<Row>
						<Col md='12'>
							<div
								aria-multiselectable={true}
								className='card-collapse'
								id='accordion'
								role='tablist'
							>
								<Card className='card-plain'>
									<CardHeader id='headingOne' role='tab'>
										<a
											style={{ fontSize: '20px', cursor: 'pointer' }}
											aria-expanded={collapses.includes(1)}
											data-toggle='collapse'
											onClick={(e) => {
												e.preventDefault();
												changeCollapse(1);
											}}
										>
											1. Profilaktyka
											<i className='now-ui-icons arrows-1_minimal-down'></i>
										</a>
									</CardHeader>

									<Collapse isOpen={collapses.includes(1)}>
										<CardBody>
											<p
												className='hiw-faq-part-info'
												style={{ lineHeight: '1.7em', fontSize: '1.1em' }}
											>
												<span
													className='hiw-faq-part-title'
													style={{ fontSize: '1.1em' }}
												>
													Tematy poruszone w kursie:
												</span>
												<br />
												<br />
												1.{' '}
												<span className='hiw-faq-part-title'>
													Profilaktyka uzależnień alkoholowych{' '}
												</span>
												<br />
												2.{' '}
												<span className='hiw-faq-part-title'>
												Profilaktyka uzależnień narkotykowych{' '}
												</span>
												<br />
												3.{' '}
												<span className='hiw-faq-part-title'>
													Profilaktyka uzależnień behawioralnych{' '}
												</span>
											</p>
										</CardBody>
									</Collapse>
								</Card>
								<Card className='card-plain'>
									<CardHeader id='headingTwo' role='tab'>
										<a
											aria-expanded={collapses.includes(2)}
											style={{ fontSize: '20px', cursor: 'pointer' }}
											data-toggle='collapse'
											onClick={(e) => {
												e.preventDefault();
												changeCollapse(2);
											}}
										>
											2. Warsztaty z kompetencji miękkich
											<i className='now-ui-icons arrows-1_minimal-down'></i>
										</a>
									</CardHeader>
									<Collapse isOpen={collapses.includes(2)}>
										<CardBody>
											<p
												className='hiw-faq-part-info'
												style={{ lineHeight: '1.7em', fontSize: '1.1em' }}
											>
												<span
													className='hiw-faq-part-title'
													style={{ fontSize: '1.1em' }}
												>
													Tematy poruszone w kursie:
												</span>
												<br />
												<br />
												1.{' '}
												<span className='hiw-faq-part-title'>
													Warsztat Asertywności{' '}
												</span>
												<br />
												2.{' '}
												<span className='hiw-faq-part-title'>
													Warsztat Pewności Siebie
												</span>
												<br />
												3.{' '}
												<span className='hiw-faq-part-title'>
													Warsztat Komunikacji w Grupie
												</span>
												<br />
												4.{' '}
												<span className='hiw-faq-part-title'>
													Warsztat Wyznaczania Celów
												</span>
											</p>
										</CardBody>
									</Collapse>
								</Card>
								<Card className='card-plain'>
									<CardHeader id='headingThree' role='tab'>
										<a
											aria-expanded={collapses.includes(3)}
											style={{ fontSize: '20px', cursor: 'pointer' }}
											data-toggle='collapse'
											onClick={(e) => {
												e.preventDefault();
												changeCollapse(3);
											}}
										>
											3. Zadanie projektowe
											<i className='now-ui-icons arrows-1_minimal-down'></i>
										</a>
									</CardHeader>
									<Collapse isOpen={collapses.includes(2)}>
										<CardBody>
											<p
												className='hiw-faq-part-info'
												style={{ lineHeight: '1.7em', fontSize: '1.1em' }}
											>
												<span
													className='hiw-faq-part-title'
													style={{ fontSize: '1.1em' }}
												>
													Tematy poruszone w kursie:
												</span>
												<br />
												<br />
												1.{' '}
												<span className='hiw-faq-part-title'>
													Alkohol? Nie, dziękuję - warsztaty z projektowania
												</span>
												<br />
												2.{' '}
												<span className='hiw-faq-part-title'>
												Narkotykom mówię nie - zajęcia projektowe
												</span>
												<br />
												3.{' '}
												<span className='hiw-faq-part-title'>
												Profilaktyka uzależnień behawioralnych - zajęcia projektowe
												</span>
											</p>
										</CardBody>
									</Collapse>
								</Card>
                                <Card className='card-plain'>
									<CardHeader id='headingThree' role='tab'>
										<a
											aria-expanded={collapses.includes(4)}
											style={{ fontSize: '20px', cursor: 'pointer' }}
											data-toggle='collapse'
											onClick={(e) => {
												e.preventDefault();
												changeCollapse(4);
											}}
										>
											4. Bezpieczna przyszłość
											<i className='now-ui-icons arrows-1_minimal-down'></i>
										</a>
									</CardHeader>

									<Collapse isOpen={collapses.includes(4)}>
										<CardBody>
											<p
												className='hiw-faq-part-info'
												style={{lineHeight: '1.7em', fontSize: '1.1em'}}
											>
												<span
													className='hiw-faq-part-title'
													style={{fontSize: '1.1em'}}
												>
													Tematy poruszone w kursie:
												</span>
												<br/>
												<br/>
												1.{' '}
												<span className='hiw-faq-part-title'>
													Stres w szkole
												</span>
												<br/>
												2.{' '}
												<span className='hiw-faq-part-title'>
												Napoje energetyczne
												</span>
												<br/>
												3.{' '}
												<span className='hiw-faq-part-title'>
												Mowa nienawiści
												</span>
												<br/>
												4.{' '}
												<span className='hiw-faq-part-title'>
												Podpuszczanie
												</span>
												<br/>
												5.{' '}
												<span className='hiw-faq-part-title'>
												Bezpieczny wypoczynek
												</span>
												<br/>
												6.{' '}
												<span className='hiw-faq-part-title'>
												Papierosy i e-papierosy
												</span>
											</p>
										</CardBody>
									</Collapse>


								</Card>
							</div>
						</Col>
					</Row>
				</div>
			</div>
		</>
	);
}

export default LykeStudents;
